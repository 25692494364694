import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createMuiTheme} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles";
import upperSymbols from "./images/upperSimbolsBBC.png";
import bbc from "./images/getviralLogo.png";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import firebase from "./firebase";
import Paper from "@material-ui/core/Paper";
import FlipMove from 'react-flip-move';
import logo from "./images/gooseLogo.png";
import logo2 from "./images/bohemiaLogo.png";

function isValidPoc(pocCode) {
    var arr =
        ["MA2"];
    return arr.includes(pocCode);
}



export default class Validator extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            nameFromDB: '',
            dniFromDB: '',
            lastNameFromDB: '',
            name:'',
            lastName: '',
            email: '',
            dni: '',
            ID: window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1),
            headerText: '',
            campaignName: '',
            discAmount: '',
            endDate: '',
            campID: '',
            otherBen: '',
            pocCode: '',
            isValid: false,
            isInvalid: false,
            redeemed: false,
            error: false,
        };
    }

    componentDidMount() {
        if (this.state.ID){
            var db = firebase.firestore();
            var context = this;
            db.collection('users').doc(this.state.ID).get().then(function (doc) {
                if (doc.exists){

                    if (doc.data().validated){
                        context.setState({
                            isInvalid: true
                        });
                    } else {
                        context.setState({
                            isValid: true
                        });
                    }

                    context.setState({nameFromDB: doc.data().name, dniFromDB: doc.data().dni, emailfromDB: doc.data().email,
                        lastNameFromDB: doc.data().lastName, campID: doc.data().campaign});
                    db.collection('campaigns').doc(doc.data().campaign).get().then(function (camp) {

                        if (camp.exists) {

                            context.setState({campaignName: camp.data().name, discAmount: camp.data().discAmount, endDate: camp.data().endDate, otherBen: camp.data().otherBen});

                        }

                    })
                }
                else {
                    context.setState({
                        isInvalid: true,
                    })
                }
            })
        }
    }
    handleChange = name => (event) => {
        var context = this;
        this.setState({
            [name]: event.target.value,
            error: false,
        });
    };

    onRedeemed = () =>{
        if (this.state.pocCode){
            if (isValidPoc(this.state.pocCode)){
                var db = firebase.firestore();
                var context = this;
                let timestamp = firebase.firestore.FieldValue.serverTimestamp();
                db.collection('users').doc(this.state.ID).update({
                    validated: true,
                    pocCode: this.state.pocCode,
                    redeemedAt: timestamp,
                }).then(function (doc) {
                        context.setState({
                            redeemed: true,
                        });
                        var campRef = db.collection('campaigns').doc(context.state.campID);
                        db.runTransaction(function (transaction) {
                            return transaction.get(campRef).then(function (camp) {
                                if (!camp.exists) {
                                    throw "Document does not exist!";
                                }
                                var newConversions = camp.data().conversions + 1;


                                var pocs = camp.data().pocs;

                                var pocCount = pocs[context.state.pocCode];
                                if (pocCount){
                                    var newPocCount = pocCount + 1;
                                }
                                else {
                                    var newPocCount =  1;
                                }

                                pocs[context.state.pocCode] = newPocCount;
                                var data = {};
                                data.conversions = newConversions;
                                data.pocs = pocs;


                                /*var pocCount = camp.data()[context.state.pocCode];

                                if (pocCount){
                                    var newPocCount = pocCount + 1;
                                }
                                else {
                                    var newPocCount =  1;
                                }

                                var data = {};
                                data.conversions = newConversions;
                                data[context.state.pocCode] = newPocCount;*/
                                transaction.update(campRef, data);
                                return newConversions;

                            });
                        }).then(function (newPopulation) {


                        }).catch(function (err) {
                            // This will be an "population is too big" error.
                            console.error(err);
                        });
                    }
                );
            } else {
                this.setState({
                    error: true,
                })
            }

        } else {
            this.setState({
                error: true,
            })
        }


    };

    render() {

        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#ffffff',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    contrastText: '#000000'
                },
                secondary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#fff',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    contrastText: '#17BA42'
                }
            },
            typography: { useNextVariants: true },
        });
        return (
            <MuiThemeProvider theme={theme}>
                <div>
                   {/* <div className={'upper'}>
                        <p>
                            *APLICA TYC, CONSÚLTELOS <i className="ion ion-logo-facebook"></i>@BBCCERVECERIA, PROHIBESE EL EXPENDIO DE BEBIDAS EMBRIAGANTES A MENORES DE EDAD. EL EXECESO DE ALCOHOL ES PERJUDICIAL PARA LA SALUD.
                            <img className={'upperSymbols'} alt={'upperSymbols'} src={upperSymbols}/>
                        </p>
                    </div>*/}

                    <div  className={'logoContainer'}>
                        <img className={'logo logoValidator'} src={logo}/>
                    </div>

                    <FlipMove>
                        {this.state.isValid && !this.state.redeemed &&
                        <div>
                            <p className={'startLoginPTitle birthConsentTitle'}> {
                                'Confirme suas informações'
                            } </p>
                            <Paper className={'validatorDataContainer'}>
                                <p className={'startLoginPTitle'}> {
                                    'Nome: '
                                }
                                    <span>
                                {this.state.nameFromDB}
                            </span>
                                </p>
                                <p className={'startLoginPTitle'}> {
                                    'Sobrenome: '
                                }
                                    <span>
                                {this.state.lastNameFromDB}
                            </span>
                                </p>
                                <p className={'startLoginPTitle'}> {
                                    'ID: '
                                }
                                    <span>
                                {this.state.dniFromDB}
                            </span>
                                </p>
                            </Paper>
                            <TextField
                                value={this.state.pocCode}
                                onChange={this.handleChange('pocCode')}
                                error={this.state.error}
                                label="Código de bar"
                                className="fieldModal loginDetails birthConsentField"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    classes: {
                                        root: '',
                                        focused: 'inputCustomLogin',
                                        error: 'errorInput',
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        root: 'inputCustomLogin',
                                        focused: 'inputCustomLogin',
                                        notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                        error: 'errorInput'
                                    },
                                }}
                            />
                            <Button onClick={this.onRedeemed} variant="contained" color="primary"
                                    className={"loginButton modalButton loginStartButton buyButton2 birthConsentButton"}>
                                VALIDAR
                            </Button>
                        </div>
                        }
                        {
                            this.state.isInvalid && !this.state.redeemed &&
                            <div>
                                <p className={'startLoginPTitle birthConsentTitle'}> {

                                } </p>
                                <Paper className={'validatorDataContainer'}>

                                    <p className={'startLoginPTitle'}> {
                                        'Codigo ya utilizado'
                                    }
                                    </p>

                                </Paper>
                            </div>
                        }
                        {this.state.redeemed &&
                        <div>
                            <p className={'startLoginPTitle birthConsentTitle'}> {
                                'Parabéns! Mostrar esta tela para o pubmanager'
                            } </p>
                            <Paper className={'validatorDataContainer validatorSuccessContainer'}>
                                <p className={'startLoginPTitle'}> {
                                    'Nome: '
                                }
                                    <span>
                                {this.state.nameFromDB}
                            </span>
                                </p>
                                <p className={'startLoginPTitle'}> {
                                    'Sobrenome: '
                                }
                                    <span>
                                {this.state.lastNameFromDB}
                            </span>
                                </p>
                                <p className={'startLoginPTitle'}> {
                                    'CPF: '
                                }
                                    <span>
                                {this.state.dniFromDB}

                            </span>
                                </p>
                                <p className={'startLoginPTitle'}> {
                                    'Codigo BAR: '
                                }
                                    <span>
                                {this.state.pocCode}

                            </span>
                                </p>

                            </Paper>
                        </div>
                        }
                    </FlipMove>





                </div>
            </MuiThemeProvider>

        )
    }
}