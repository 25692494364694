import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import './Ranking.css';
import effort from './images/effort.svg';
import start from './images/start.svg';
import saturn from './images/saturn.svg';
import { Line } from 'rc-progress';
import Stepper from "react-stepper-horizontal";
import logo from "./images/bohemiaLogo.png";
import firebase from "./firebase";


const styles = theme => ({

});



export default withStyles(styles)(
    class Ranking extends React.Component {

        static propTypes = {
            user: PropTypes.object,
        };

        constructor(props) {
            super(props);
            this.state = {
                progress: '0',
                activeStep: 0,
                shares: 0,
                name: '',
                lastName: '',
                email: '',
                id: '',
                topShares: 0,
            };
            if (this.state.activeStep === 0){
                this.state.activeColor = '#C444FE';
                this.state.completedColor = 'rgba(196,68,254,0.5)';
            }
            else if (this.state.activeStep === 1){
                this.state.activeColor = '#C444FE';
                this.state.completedColor = 'rgba(196,68,254,0.5)';
            }
            else if (this.state.activeStep === 2){
                this.state.activeColor = '#C444FE';
                this.state.completedColor = 'rgba(196,68,254,0.5)';
            }

        }

        componentDidMount() {

            if (this.props.shares > 10){
                this.updateStep(1)
            }
            if (this.props.shares > 100){
                this.updateStep(2)
            }

            this.setState({
                name: this.props.name,
                lastName: this.props.lastName,
                id: this.props.id,
                email: this.props.email,
                shares: this.props.shares
            });

            var db = firebase.firestore();
            db.collection('users').where('campaign', '==', 'patagoniaoct').orderBy("shareToRegister", "desc").limit(1)
                .get().then(snap => {
                    snap.forEach(user => {
                        let topShares = user.data().shareToRegister;

                        let percent = Math.round((this.state.shares/topShares)*100);


                        this.setState({
                            topShares: user.data().shareToRegister,
                            progress: percent.toString()
                        })
                    })
            })



        }
        updateStep = (step) =>{
            if (step === 0){
                this.setState({
                    activeStep: step,
                    activeColor: '#C444FE',
                    completedColor: 'rgba(196,68,254,0.50)'
                })
            }
            else if (step === 1){
                this.setState({
                    activeStep: step,
                    activeColor: '#C444FE',
                    completedColor: 'rgba(196,68,254,0.50)'
                })
            }
            else if (step === 2){
                this.setState({
                    activeStep: step,
                    activeColor: '#C444FE',
                    completedColor: 'rgba(196,68,254,0.50)'
                })
            }
        };

        componentWillReceiveProps(nextProps, nextContext) {
            if (nextProps.name !== this.props.name){
                this.setState({
                    name: nextProps.name,
                    lastName: nextProps.lastName,
                    id: nextProps.id,
                    email: nextProps.email,
                    shares: nextProps.shares
                })
            }
        }

        render() {
            const {classes} = this.props;
            let image = effort;
            if (this.state.activeStep === 1){
                image = start
            }else if (this.state.activeStep === 2){
                image = saturn;
            }

            return (
                <div className={'rankingContainer'}>
                    <div  className={'logoContainer rankingLogoContainer'}>
                        <img className={'logoRanking'} src={logo}/>
                        <span className={''}> {
                            'Hola ' + this.state.name + '!'
                        } </span>
                    </div>

                    <div className={'rankStatusContainer'}>
                        <div style={{backgroundColor: this.state.activeColor}} className={'rankStatusCircle'}><img alt={'iconRankStatus'} className={'iconRankStatus'} src={image}/></div>
                        <div className={'rankStatusInfoContainer'}>
                        <span className={''}> {
                            this.state.activeStep === 0 && 'Beginner'
                        }
                            {this.state.activeStep === 1 && 'Intermediate'}
                            {this.state.activeStep === 2 && 'Top Influencer'}
                            <span className={'rankStatusInfoShares'}>
                                {this.state.shares +' Shares'}
                            </span>
                        </span>
                            <Line trailColor={'#ffffff'} percent={this.state.progress} strokeWidth="4" strokeColor={this.state.activeColor} trailWidth='4' />
                            <span className={'missingShares'}>{'Te faltan ' + (this.state.topShares - this.state.shares) +' para alcanzar la punta'}</span>
                        </div>
                    </div>


                    <Stepper completeBarColor={this.state.activeColor} activeColor={this.state.activeColor} completeColor={this.state.completedColor} activeTitleColor={'#F8F3E4'} completeTitleColor={'#aeaeae'} titleFontSize={12} size={50} steps={ [{title: 'Beginner', icon: effort }, {title: 'Intermediate', icon: start}, {title: 'Top Influencer', icon: saturn}] } activeStep={ this.state.activeStep } />

                    <div className={'prizesContainer'}>
                    <span className={'prizesTitle'}> {
                        'Premios'
                    } </span>

                    </div>
                    <div className={'rankStatusContainer'}>
                        <div style={{backgroundColor: this.state.activeColor}}  className={'rankStatusCircle circlePrizes'}><img alt={'iconRankStatus'} className={'iconRankStatus'} src={effort}/></div>
                        <div className={'rankStatusInfoContainer'}>
                        <span className={''}> {
                            'Mas Viral '
                        }
                        </span>
                            <span className={'missingShares msPrizes'}>{'Una cena para 6 personas + 1 growler con recarga para cada invitado.'}</span>
                        </div>
                    </div>
                    {/*<div className={'rankStatusContainer'}>
                        <div className={'rankStatusCircle circlePrizes intermediate'}><img alt={'iconRankStatus'} className={'iconRankStatus'} src={effort}/></div>
                        <div className={'rankStatusInfoContainer'}>
                        <span className={''}> {
                            'Beginner '
                        }
                        </span>
                            <span className={'missingShares msPrizes'}>{'Te faltan 15 para subir de nivel'}</span>
                        </div>
                    </div>
                    <div className={'rankStatusContainer'}>
                        <div className={'rankStatusCircle circlePrizes expert'}><img alt={'iconRankStatus'} className={'iconRankStatus'} src={effort}/></div>
                        <div className={'rankStatusInfoContainer'}>
                        <span className={''}> {
                            'Beginner '
                        }
                        </span>
                            <span className={'missingShares msPrizes'}>{'Te faltan 15 para subir de nivel'}</span>
                        </div>
                    </div>*/}
                    <div className={'prizesContainer'}>
                    {/*<span className={'prizesTitle'}> {
                        'Invita a tus amigos y suma'
                    } </span>*/}

                    </div>
                </div>
            )
        }
    })